import React, { useEffect, useState } from "react";
import {
    Badge,
    Button,
    Card,
    Container,
    Modal,
    Row,
    Col,
    Form,
} from "react-bootstrap";
import Select from "react-select";
import { Helmet } from "react-helmet-async";

import FullCalendar from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import api from "../../../service/api";
import { addDefaultOption } from "../../../utils/utility";

const SchedulingCalendar = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [schedulesLists, setSchedulesList] = useState([]);
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        paymentMode: [],
        additionalTraining: [],
        atoCourses: [],
        attcCourses: [],
        nationality: [],
        companies: [],
        registrationOption: [],
        workPermits: [],
    });
    const [displayLists, setDisplayList] = useState([]);
    const [schedule, setSchedule] = useState({});
    const [filterValues, setFilterValues] = useState({
        training_date: true,
        test_date: true,
        trade_category: null,
        course_id: null,
    });

    const [selectedEvent, setSelectedEvent] = useState({
        id: null,
        type: null,
    });

    const [currentMonth, setCurrentMonth] = useState({
        start_date: null,
        end_date: null,
    });

    const handleEventClick = ({ event, el }) => {
        setModalLoading(true);
        setSchedule(null);
        setShow({ show: !show });
        getSpecificSchedule(event.id);
        setSelectedEvent({
            id: event.id,
            ...event?._def?.extendedProps,
        });
        setModalLoading(false);
    };

    const filterList = (toBeFiltered) => {
        let filteredList = toBeFiltered.filter((array) => {
            if (filterValues.training_date && filterValues.test_date) {
                return (
                    array.type === "training_date" || array.type === "test_date"
                );
            }

            if (filterValues.training_date) {
                return array.type === "training_date";
            }

            if (filterValues.test_date) {
                return array.type === "test_date";
            }

            return false;
        });

        if (filterValues.trade_category) {
            filteredList = filteredList.filter((array) => {
                return array.trade_category === filterValues.trade_category;
            });
        }

        if(filterValues.course_id){
            filteredList = filteredList.filter((array) => {
                return array.course_id === filterValues.course_id;
            });
        }

        return filteredList;
    };

    const handleMonthChange = async (payload) => {
        var formatedPayload = {
            start_date: payload.startStr,
            end_date: payload.endStr,
        };

        // Determining if it is a new month
        if (JSON.stringify(currentMonth) != JSON.stringify(formatedPayload)) {
            setDisplayList(null);
            // When the current month is changed
            // there is a useEffect that will query the schedules
            setCurrentMonth(formatedPayload);
        }
    };

    const getSchedules = async (request = null) => {
        setLoading(true);
        const response = await api.get(`tms/schedules`, { ...request });
        setSchedulesList(response.data);
        setLoading(false);
    };

    const getScheduleConfig = async () => {
        const configResponse = await api.get(`tms/enrolments/config`);
        setConfigValues({ ...configValues, ...configResponse.data });
    }

    const getSpecificSchedule = async (id) => {
        const response = await api.get(`tms/batches/${id}`, {});
        setSchedule(response.data);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFilterValues((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    useEffect(() => {
        getScheduleConfig();
    },[])

    useEffect(() => {
        if (currentMonth.start_date && currentMonth.end_date) {
            getSchedules(currentMonth);
        }
    }, [currentMonth]);

    useEffect(() => {
        setDisplayList(filterList(schedulesLists));
    }, [filterValues, schedulesLists]);

    return (
        <React.Fragment>
            <Helmet title="Schedules > Scheduling calendar" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Scheduling calendar</h1>

                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <h6 className="pt-2">Filters:</h6>
                                <Select
                                    className="react-select-container mb-3"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption(
                                        configValues?.tradeCategory
                                    )}
                                    name="trade_category"
                                    onChange={(value) => {
                                        setFilterValues({
                                            ...filterValues,
                                            trade_category:
                                                value?.value ?? null,
                                        });
                                    }}
                                    placeholder="Select trade category"
                                    value={
                                        filterValues?.trade_category &&
                                        configValues?.tradeCategory?.filter(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    filterValues?.trade_category
                                                );
                                            }
                                        )
                                    }
                                    isClearable={true}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 999,
                                        }),
                                    }}
                                />
                                <Select
                                    className="react-select-container mb-3"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption(
                                        configValues?.courses
                                    )}
                                    name="course_id"
                                    onChange={(value) => {
                                        setFilterValues({
                                            ...filterValues,
                                            course_id:
                                                value?.value ?? null,
                                        });
                                    }}
                                    placeholder="Select course"
                                    value={
                                        filterValues?.course_id &&
                                        configValues?.courses?.filter(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    filterValues?.course_id
                                                );
                                            }
                                        )
                                    }
                                    isClearable={true}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 999,
                                        }),
                                    }}
                                />
                                <dl>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="test_date"
                                            checked={filterValues.test_date}
                                            onChange={handleCheckboxChange}
                                        />
                                        <dd
                                            style={{
                                                display: "inline-block",
                                                margin: "0px 10px",
                                                paddingBottom: 0,
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            Test Date
                                        </dd>
                                        <dt
                                            style={{
                                                display: "inline-block",
                                                width: "18px",
                                                height: "18px",
                                                verticalAlign: "middle",
                                                background: "#ffffbf",
                                                border: "4px",
                                                borderColor: "#00000",
                                            }}
                                        />
                                    </label>
                                    <br />

                                    <label>
                                        <input
                                            type="checkbox"
                                            name="training_date"
                                            checked={filterValues.training_date}
                                            onChange={handleCheckboxChange}
                                        />

                                        <dd
                                            style={{
                                                display: "inline-block",
                                                margin: "0px 10px",
                                                paddingBottom: 0,
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            Training Date
                                        </dd>
                                        <dt
                                            style={{
                                                display: "inline-block",
                                                width: "18px",
                                                height: "18px",
                                                verticalAlign: "middle",
                                                background: "#e3e3ff",
                                            }}
                                        />
                                    </label>
                                </dl>
                            </Col>
                        </Row>
                        {loading && (
                            <>
                                <Row className="d-flex justify-content-center align-items center">
                                        Fetching schedules...
                                    <div class="ms-2 spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </Row>
                            </>
                        )}
                        {/* {!loading && ( */}
                        <>
                            <Row className="pt-4">
                                <Col md={12}>
                                    <FullCalendar
                                        plugins={[
                                            bootstrapPlugin,
                                            dayGridPlugin,
                                            timeGridPlugin,
                                        ]}
                                        themeSystem="bootstrap"
                                        eventOrder={"ordering, title"}
                                        eventOrderStrict={true}
                                        initialView="dayGridMonth"
                                        initialDate={new Date()}
                                        headerToolbar={{
                                            left: "title",
                                            center: "",
                                            right: "prev next today",
                                        }}
                                        events={displayLists}
                                        eventClick={handleEventClick}
                                        bootstrapFontAwesome={false}
                                        datesSet={handleMonthChange}
                                    />
                                </Col>
                            </Row>
                        </>
                        {/* )} */}
                    </Card.Body>
                </Card>
            </Container>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <h3>Schedule information</h3>
                </Modal.Header>
                <Modal.Body className="text-center m-3">
                    {modalLoading && <div>Loading...</div>}
                    {!modalLoading && schedule && (
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th className="text-start">Class/Batch</th>
                                    <td className="text-start">
                                        {schedule?.name ?? ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-start">
                                        Test date
                                    </th>
                                    <td className="text-start">
                                        {schedule?.test_date ?? ""}
                                    </td>
                                </tr>
                                {selectedEvent?.type === "training_date" && (
                                    <>
                                        <tr>
                                            <th className="text-start">
                                                Training type
                                            </th>
                                            <td className="text-start">
                                                {selectedEvent?.training_type ??
                                                    ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-start">
                                                Training date
                                            </th>
                                            <td className="text-start">
                                                {selectedEvent?.event_date ??
                                                    ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-start">
                                                Total no. of students:
                                            </th>
                                            <td className="text-start">
                                                {selectedEvent?.total_students ??
                                                    ""}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <th className="text-start">Location</th>
                                    <td className="text-start">
                                        {selectedEvent?.location ??
                                            schedule?.location ??
                                            ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-start">
                                        Trade category
                                    </th>
                                    <td className="text-start">
                                        {schedule?.trade_category ?? ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-start">
                                        Course
                                    </th>
                                    <td className="text-start">
                                        {schedule?.course?.name ?? ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-start">Status</th>
                                    <td className="text-start">
                                        {schedule?.status && (
                                            <Badge>{schedule.status}</Badge>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-start">Invigilators</th>
                                    <td>
                                        <ul className="list-unstyled mb-0">
                                            {schedule?.invigilators?.map(
                                                (invigilator, index) => {
                                                    return (
                                                        <li
                                                            key={`invigilators-${index}`}
                                                            className="pb-2"
                                                        >
                                                            <div className="d-flex justify-content-start">
                                                                <div>
                                                                    {index + 1}
                                                                </div>{" "}
                                                                <div className="m-1"></div>
                                                                <div>
                                                                    {
                                                                        invigilator.name
                                                                    }
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </td>
                                </tr>
                                {selectedEvent?.type === "test_date" && (
                                    <tr>
                                        <td colSpan={2}>
                                            <h4 className="text-start">
                                                Training dates
                                            </h4>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>S.no</th>
                                                        <th> Date</th>
                                                        <th>Session</th>
                                                        <th>Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {schedule?.training_dates?.map(
                                                        (dates, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {i + 1}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            dates.date
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {(dates
                                                                            ?.am[
                                                                            "from"
                                                                        ]
                                                                            ? dates
                                                                                  ?.am[
                                                                                  "from"
                                                                              ] +
                                                                              " - "
                                                                            : "") +
                                                                            (dates
                                                                                ?.am[
                                                                                "to"
                                                                            ] ??
                                                                                "") +
                                                                            (dates
                                                                                ?.pm[
                                                                                "from"
                                                                            ]
                                                                                ? dates
                                                                                      ?.pm[
                                                                                      "from"
                                                                                  ] +
                                                                                  " - "
                                                                                : "") +
                                                                            (dates
                                                                                ?.pm[
                                                                                "to"
                                                                            ] ??
                                                                                "")}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            dates.training_type
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default SchedulingCalendar;
