import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import { useEffect, useState, useRef } from "react";


const ItemModal = ({ handleClose, courseFee, selectedItem }) => {
  // const [search, setSearch] = useState("");
  const [search, setSearch] = useState({ code: "", name: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100; // Number of items per page

  // Filter courses based on search query
  const filteredCourses = courseFee
  ? courseFee.filter(course =>
      course.course_code.toLowerCase().includes(search.code.toLowerCase()) &&
      (course.course_name ? course.course_name.toLowerCase().includes(search.name.toLowerCase()) : true)
    )
  : [];

  // Pagination logic
  const totalItems = filteredCourses.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedCourses = filteredCourses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Modal size="xl" show={selectedItem.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Item List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Search Bar - Fixed */}
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search by Code"
            value={search.code}
            onChange={(e) => {
              setSearch(prev => ({ ...prev, code: e.target.value }));
              setCurrentPage(1);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
              type="text"
              placeholder="Search by Name"
              value={search.name}
              onChange={(e) => {
                setSearch(prev => ({ ...prev, name: e.target.value }));
                setCurrentPage(1);
              }}
            />
        </Form.Group>

        {/* Scrollable Table */}
        <div style={{ maxHeight: "50dvh", overflowY: "auto" }}>
          <Table size="sm" responsive hover>
            <thead className="table-light">
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Course Name</th>
                <th>Trade Category</th>
                <th>Type of Trade</th>
                <th>Application Option</th>
                <th>Fee</th>
              </tr>
            </thead>
            <tbody>
              {paginatedCourses.length > 0 ? (
                paginatedCourses.map((course, index) => (
                  <tr
                    key={index}
                  >
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{course.course_code}</td>
                    <td>{course.course_name ?? "-"}</td>
                    <td>{course.trade_category ?? "-"}</td>
                    <td>{course.type_of_trade}</td>
                    <td>{course.application_option}</td>
                    <td>${course.fee}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No item available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {/* Pagination Controls - Fixed */}
        {totalItems > itemsPerPage && (
          <div className="d-flex justify-content-between align-items-center mt-3">
            <span>
              Showing {paginatedCourses.length} of {totalItems} items
            </span>
            <Pagination className="mb-0">
              <Pagination.Prev
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              />
              <Pagination.Next
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
            // setCurrentPage(1);
            // setSearch("");
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemModal;
