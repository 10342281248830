import React, { useCallback, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Search } from "react-feather";
import { AsyncPaginate } from "react-select-async-paginate";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";

import { UserDateFormat } from "../../../utils/dateFormat";
import { addDefaultOption } from "../../../utils/utility";
import api from "../../../service/api";

const SearchEnrolment = ({
    setSearchValue,
    searchValue,
    search,
    defaultConfig,
    resetSearch,
    isPendingStudents = false,
    attcTestDate = false,
    salesPersons,
}) => {
    const DateFormat = UserDateFormat();
    const [advanceSearch, setAdvanceSearch] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        paymentMode: [],
        additionalTraining: [],
        atoCourses: [],
        attcCourses: [],
        nationality: [],
        companies: [],
        registrationOption: [],
        workPermits: [],
        cetHide: [],
    });

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data.type?.toLowerCase() === "ato";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toLowerCase() === "attc";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const { atoCourses, attcCourses } = { ...configValues };

        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigValues({ atoCourses, attcCourses });
    };

    const setCompanyData = (data) => {
        const formatData = data.map((data, index) => {
            return { value: data.id, label: data.name };
        });

        const { companies } = { ...configValues };
        companies.push(...formatData);
        setConfigValues(companies);
    };

    const getConfig = useCallback(async () => {
        try {
            const configResponse = await api.get(`tms/enrolments/config`, {});
            const courseResponse = await api.get(`tms/courses?all=1`, {});

            // const companyResponse = await api.get(`tms/companies?all=1`, {});

            setCourseData(courseResponse?.data);

            // setCompanyData(companyResponse?.data);

            setConfigValues({ ...configValues, ...configResponse.data });
            setLoading(false);
        } catch (error) {}
    }, []);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    const [hasMore, setHasMore] = useState(true);

    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const handleChange = event => {
        setIsChecked(event.target.checked);
        setSearchValue({
            ...searchValue,
            hide_cet:
            event.target.checked,
        });
      };

    return (
        <>
            {loading && "Loading..."}
            {!loading && (
                <>
                    <Row>
                        <Col md={3}>
                            <Form.Control
                                className="d-inline-block mb-3"
                                size="lg"
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={searchValue.name}
                                onChange={(e) => {
                                    setSearchValue({
                                        ...searchValue,
                                        name: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Control
                                size="lg"
                                type="text"
                                name="fin"
                                className="mb-3"
                                placeholder="Input NRIC/FIN"
                                onChange={(e) => {
                                    setSearchValue({
                                        ...searchValue,
                                        fin: e.target.value,
                                    });
                                }}
                                value={searchValue.fin}
                            />
                        </Col>
                        
                        <Col md={3}>
                            <DatePicker
                                selected={
                                    searchValue?.date !== "" &&
                                    searchValue?.date !== null &&
                                    new Date(searchValue?.date)
                                }
                                className={`form-control form-control-lg`}
                                size="lg"
                                onChange={(dates) => {
                                    setSearchValue({
                                        ...searchValue,
                                        date:
                                            dates === null
                                                ? null
                                                : moment(dates).format(
                                                      "YYYY-MM-DD"
                                                  ),
                                    });
                                }}
                                dateFormat={DateFormat}
                                placeholderText={`Select ${
                                    isPendingStudents ? "application" : "test"
                                } date`}
                                isClearable={
                                    searchValue?.date !== "" &&
                                    searchValue?.date !== null &&
                                    true
                                }
                            />
                        </Col>
                        <Col md={3}>
                            <Select
                                className="is-invalid react-select-container"
                                classNamePrefix="react-select"
                                options={addDefaultOption(
                                    defaultConfig?.registrationOption
                                )}
                                name="registration_type"
                                onChange={(value) => {
                                    setSearchValue({
                                        ...searchValue,
                                        registration_type: value.value,
                                    });
                                }}
                                placeholder="Select registration"
                                value={
                                    searchValue?.registration_type &&
                                    defaultConfig?.registrationOption?.filter(
                                        (option) => {
                                            return (
                                                option.value ===
                                                searchValue?.registration_type
                                            );
                                        }
                                    )
                                }
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <AsyncPaginate
                                isClearable
                                loadOptions={handleLoadMore}
                                hasMore={hasMore}
                                placeholder="Search sponsor company"
                                additional={{
                                    page: 1,
                                }}
                                onChange={(value) => {
                                    setSearchValue({
                                        ...searchValue,
                                        sponsor_company_id: value
                                            ? value?.value
                                            : "",
                                    });
                                }}
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                                debounceTimeout={1000}
                            />
                        </Col>
                        <Col md={3}>
                            <AsyncPaginate
                                isClearable
                                loadOptions={handleLoadMore}
                                hasMore={hasMore}
                                placeholder="Search company"
                                additional={{
                                    page: 1,
                                }}
                                onChange={(value) => {
                                    setSearchValue({
                                        ...searchValue,
                                        company_id: value
                                            ? value?.value
                                            : "",
                                    });
                                }}
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                                debounceTimeout={1000}
                            />
                        </Col>
                    
                        <Col md={3}>
                            <Form.Group>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption(
                                        configValues?.tradeCategory
                                    )}
                                    name="trade_category"
                                    onChange={(value) => {
                                        setSearchValue({
                                            ...searchValue,
                                            trade_category:
                                                value.value,
                                        });
                                    }}
                                    placeholder="Select trade category"
                                    value={
                                        searchValue?.trade_category &&
                                        configValues?.tradeCategory?.filter(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    searchValue?.trade_category
                                                );
                                            }
                                        )
                                    }
                                    styles={{
                                        menu: (
                                            provided
                                        ) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption(
                                        configValues?.tradeOptions
                                    )}
                                    name="type_of_trade"
                                    onChange={(value) => {
                                        setSearchValue({
                                            ...searchValue,
                                            type_of_trade:
                                                value.value,
                                        });
                                    }}
                                    placeholder="Select type of trade"
                                    value={
                                        searchValue?.type_of_trade &&
                                        configValues?.tradeOptions?.filter(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    searchValue?.type_of_trade
                                                );
                                            }
                                        )
                                    }
                                    styles={{
                                        menu: (
                                            provided
                                        ) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={3}>
                            <Form.Control
                                className="d-inline-block mb-3"
                                size="lg"
                                type="text"
                                placeholder="Invoice number"
                                name="invoice_no"
                                value={searchValue.invoice_no}
                                onChange={(e) => {
                                    setSearchValue({
                                        ...searchValue,
                                        invoice_no: e.target.value,
                                    });
                                }}
                            />
                        </Col>

                        <Col md={3}>
                            <Form.Group>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption(
                                        configValues?.invoiceStatus
                                    )}
                                    name="trade_category"
                                    onChange={(value) => {
                                        setSearchValue({
                                            ...searchValue,
                                            invoice_status:
                                                value.value,
                                        });
                                    }}
                                    placeholder="Select invoice status"
                                    value={
                                        searchValue?.invoiceStatus &&
                                        configValues?.invoiceStatus?.filter(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    searchValue?.invoiceStatus
                                                );
                                            }
                                        )
                                    }
                                    styles={{
                                        menu: (
                                            provided
                                        ) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={3}>
                            <Form.Group>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption(
                                        configValues?.tradeApplication
                                    )}
                                    name="application_option"
                                    onChange={(value) => {
                                        setSearchValue({
                                            ...searchValue,
                                            application_option:
                                                value.value,
                                        });
                                    }}
                                    placeholder="Select application"
                                    value={
                                        searchValue?.application_option &&
                                        configValues?.tradeApplication?.filter(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    searchValue?.application_option
                                                );
                                            }
                                        )
                                    }
                                    styles={{
                                        menu: (
                                            provided
                                        ) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        {attcTestDate && (
                            <Col md={3}>
                                <DatePicker
                                    selected={
                                        searchValue?.result_date !==
                                            "" &&
                                        searchValue?.result_date !==
                                            null &&
                                        new Date(
                                            searchValue?.result_date
                                        )
                                    }
                                    className={`form-control form-control-lg`}
                                    size="lg"
                                    onChange={(dates) => {
                                        setSearchValue({
                                            ...searchValue,
                                            result_date:
                                                dates ===
                                                null
                                                    ? null
                                                    : moment(
                                                            dates
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        ),
                                        });
                                    }}
                                    dateFormat={DateFormat}
                                    placeholderText={`Select ATTC test date`}
                                    isClearable={
                                        searchValue?.result_date !==
                                            "" &&
                                        searchValue?.result_date !==
                                            null &&
                                        true
                                    }
                                />
                            </Col>
                        )}
                        <Col md={3}>
                            <Form.Group>
                                <Select
                                    className="is-invalid react-select-container"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption(
                                        searchValue?.type?.toLowerCase() ===
                                            "ato"
                                            ? configValues?.atoCourses
                                            : configValues?.attcCourses
                                    )}
                                    name="course_id"
                                    onChange={(value) => {
                                        setSearchValue({
                                            ...searchValue,
                                            course_id: value.value,
                                        });
                                    }}
                                    placeholder="Select course"
                                    value={
                                        searchValue?.type?.toLowerCase() ===
                                        "ato"
                                            ? searchValue?.course_id &&
                                                configValues?.atoCourses?.filter(
                                                    (option) => {
                                                        return (
                                                            option.value ===
                                                            searchValue?.course_id
                                                        );
                                                    }
                                                )
                                            : searchValue?.course_id &&
                                                configValues?.attcCourses?.filter(
                                                    (option) => {
                                                        return (
                                                            option.value ===
                                                            searchValue?.course_id
                                                        );
                                                    }
                                                )
                                    }
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />

                                
                            </Form.Group>
                            
                        </Col>

                        <Col md={3}>
                            <Select
                                className="is-invalid react-select-container"
                                classNamePrefix="react-select"
                                options={addDefaultOption(
                                    defaultConfig?.status
                                )}
                                name="status"
                                onChange={(value) => {
                                    setSearchValue({
                                        ...searchValue,
                                        status: value.value,
                                    });
                                }}
                                placeholder="Select enrolment status"
                                value={
                                    searchValue?.status &&
                                    defaultConfig?.status?.filter(
                                        (option) => {
                                            return (
                                                option.value ===
                                                searchValue?.status
                                            );
                                        }
                                    )
                                }
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-end">
                    <Col md={3} className="d-flex justify-content-end">
                            <Form.Check
                                    className="mb-2"
                                    inline
                                    label="Hide CET"
                                    name="hide_cet"
                                    type="checkbox"
                                    onChange={handleChange}
                                    checked={isChecked}
                                />
                            <Button
                                size="lg"
                                variant="info"
                                className="me-1"
                                onClick={() => search({ page: 1 })}
                            >
                                <Search className="feather" />
                            </Button>
                            {(searchValue.fin !== "" ||
                                searchValue.registration_type !== "" ||
                                searchValue.date !== "") && (
                                <Button
                                    size="lg"
                                    variant="warning"
                                    className="me-1"
                                    onClick={() => resetSearch()}
                                >
                                    Reset
                                </Button>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default SearchEnrolment;
