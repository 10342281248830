import React, { useState } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { Filter } from "react-feather";
import Select from "react-select";
import { addDefaultOption } from "../../../../../utils/utility";
import { AsyncPaginate } from "react-select-async-paginate";
import api from "../../../../../service/api";
import { minSelectableYear } from "../../../../../utils/EnrolmentSummaryConstants";
import { faFileAlt, faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { DatePicker } from "antd";
import { faExclamation, faTable } from "@fortawesome/free-solid-svg-icons";


const Search = ({ setSearchValue, companies, searchValue, search, status, generateCreditNotes, requestors, searching = false, generateCreditSAP, isSummaryTitle }) => {
    const [hasMore, setHasMore] = useState(true);
    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <Row className="py-3">
            <Row className="me-2">
                
                <Col md={6} lg={3} className="mb-3">
                    <Form.Control
                        className="mx-0"
                        type="text"
                        name="number"
                        placeholder="Invoice no"
                        size="lg"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                number: event.target?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={6} lg={3} className="mb-2">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Is refund"
                        isClearable
                        options={addDefaultOption([
                            {
                                value: "yes",
                                label: "Yes",
                            },
                            {
                                value: "no",
                                label: "No",
                            },
                        ])}
                        name="refund"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                refund: event?.value,
                            });
                        }}
                    />
                </Col>
                

                <Col md={6} lg={3} className="mb-2">
                    <AsyncPaginate
                        isClearable
                        loadOptions={handleLoadMore}
                        hasMore={hasMore}
                        placeholder="Search company"
                        additional={{
                            page: 1,
                        }}
                        onChange={(value) => {
                            setSearchValue({
                                ...searchValue,
                                company: value ? value?.value : "",
                            });
                        }}
                        debounceTimeout={1000}
                    />
                </Col>

                <Col md={6} lg={3} className="mb-2">
                    <Row className="flex-row ms-md-1">
                        <Col md={5} className="p-md-0">
                            <DatePicker
                                id="from-date-picker"
                                name="from_date"
                                className={`form-control`}
                                placeholder="From date"
                                // defaultValue={moment().startOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1 }-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                        <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0 ">
                            <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                                To
                            </Badge>
                        </Col>
                        <Col md={5} className="p-md-0">
                            <DatePicker
                                id="to-date-picker"
                                name="to_date"
                                className={`form-control`}
                                placeholder="To date"
                                // defaultValue={moment().endOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1}-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                    </Row>
                </Col>

                
            </Row>
            {status === "summary" && (
                <>
                    <Row>
                        <Col md={6} lg={3} className="mb-2">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption([
                                        {
                                            value: "pending",
                                            label: "Pending",
                                        },
                                        {
                                            value: "approved",
                                            label: "Approved",
                                        },
                                        {
                                            value: "rejected",
                                            label: "Rejected",
                                        },
                                        {
                                            value: "withdraw",
                                            label: "Withdraw",
                                        },
                                    ])}
                                    isClearable
                                    name="refund"
                                    placeholder="Status..."
                                    onChange={(event) => {
                                        setSearchValue({
                                            ...searchValue,
                                            summary_status: event?.value,
                                        });
                                    }}
                                />
                            
                        </Col>
                        <Col md={6} lg={3} className="mb-2">
                            {status === "summary" && (
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption([
                                        {
                                            value: "pending",
                                            label: "Pending",
                                        },
                                        {
                                            value: "approved",
                                            label: "Approved",
                                        },
                                        {
                                            value: "rejected",
                                            label: "Rejected",
                                        },
                                    ])}
                                    isClearable
                                    name="sales_director_approval"
                                    placeholder="Sales Director Status..."
                                    onChange={(event) => {
                                        setSearchValue({
                                            ...searchValue,
                                            sales_director_approval: event?.value,
                                        });
                                    }}
                                />
                            )}
                        </Col>
                        <Col md={6} lg={3} className="mb-2">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={addDefaultOption([
                                        {
                                            value: "pending",
                                            label: "Pending",
                                        },
                                        {
                                            value: "approved",
                                            label: "Approved",
                                        },
                                        {
                                            value: "rejected",
                                            label: "Rejected",
                                        },
                                    ])}
                                    isClearable
                                    name="finance_director_approval"
                                    placeholder="Finance Director Status..."
                                    onChange={(event) => {
                                        setSearchValue({
                                            ...searchValue,
                                            finance_director_approval: event?.value,
                                        });
                                    }}
                                />
                        </Col>
                        <Col md={6} lg={3} className="mb-2">
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={addDefaultOption(requestors)}
                                isClearable
                                name="requestor"
                                placeholder="Requestor"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        requestor: event?.value,
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    
                    <Row className="d-flex justify-content-between">
                    <Col md={6} lg={3} className="mb-3">
                            <Form.Control
                                className="mx-0"
                                type="text"
                                name="number"
                                placeholder="Credit notes no"
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        cn_number: event.target?.value,
                                    });
                                }}
                            />
                        </Col>
                        <Col md={6} lg={3} className="p-0 p-md-2">
                            <Row className="flex-row ms-md-1">
                                <Col md={5} className="p-md-0">
                                    <DatePicker
                                        id="from-date-picker"
                                        name="from_approved_date"
                                        className={`form-control`}
                                        placeholder="From approved date"
                                        onChange={(event) => {
                                            setSearchValue({
                                                ...searchValue,
                                                from_approved_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                            });
                                        }}
                                        style={{minHeight: '2.5rem'}}
                                    />
                                </Col>
                                <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0 ">
                                    <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                                        To
                                    </Badge>
                                </Col>
                                <Col md={5} className="p-md-0">
                                    <DatePicker
                                        id="to-date-picker"
                                        name="to_approved_date"
                                        className={`form-control`}
                                        placeholder="To approved date"
                
                                        onChange={(event) => {
                                            setSearchValue({
                                                ...searchValue,
                                                to_approved_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                            });
                                        }}
                                        style={{minHeight: '2.5rem'}}
                                    />
                                    {/* <Form.Control
                                        type="month"
                                        name="start_date"
                                        min={`${minSelectableYear}-01`}
                                        max={`${new Date().getFullYear() + 1}-12`}
                                        size="lg"
                                        onChange={(event) => {
                                            setSearchValue({
                                                ...searchValue,
                                                end_date: event.target.value,
                                            });
                                        }}
                                    /> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
                )}
                <Row className="d-flex justify-content-md-end justify-content-center mt-2">
                    <Col xs={2} md={1} className="d-flex text-md-end text-center justify-content-end">
                        {!searching && (
                            <>
                                <Button size="lg" variant="info" onClick={() => search()}>
                                    <Filter size={15} />
                                </Button>
                                <Button
                                    className="ms-2"
                                    variant="info"
                                    type="submit"
                                    size="lg"
                                    title="Generate Credit Notes"
                                    onClick={() => generateCreditNotes()}
                                    hidden={searching}
                                    disabled={searching}
                                >
                                    <FontAwesomeIcon icon={faFileExcel} />
                                </Button>
                                {isSummaryTitle &&(
                                    <Button
                                    className="ms-2"  
                                    variant="info"
                                    type="button"
                                    size="lg"
                                    title="Excel SAP format 2"
                                    onClick={() => generateCreditSAP()}
                                    >
                                    <FontAwesomeIcon icon={faFileAlt} />
                                    </Button>
                                )}
                            </>
                            
                        )}
                        {searching && (
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Searching...</span>
                            </div>
                        )}
                    </Col>
                </Row>
            </Row>
    );
};
export default Search;
